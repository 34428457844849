<script>
export default {
  name: "AlipayTEMPLATE1",
  components: {
    "mt-header": app.Mint.Header,
    "mt-button": app.Mint.Button,
  },
  inject: ["YHKselect"],
  data() {
    return {
      pay_price: "",
      pname: "",
    };
  },
 props: ["payList", "remark"],
  mounted() {
    this.CustomScroll(this.$refs.recharge.id);
    if (this.$refs.YHKWid) {
      this.CustomScroll(this.$refs.YHKWid.id);
    }
  },
  methods: {
    commit() {
      this.$emit("send", { pay_price:this.pay_price, pname:this.pname, payList:this.payList, remark:this.remark });
      this.$emit("commit");
    },
  },
};
</script>
<template>
  <DIV
    id='recharge'
    ref='recharge'
  >
    <mt-header
      :title="getWord(['social_app3', 'payment'])"
      style="height:0.88rem;font-size: 0.35rem;"
    >
      <router-link
        to="/money"
        slot="left"
      >
        <mt-button icon="back"></mt-button>
      </router-link>
    </mt-header>
    <div class="text_">
      <P style="color:#4796fc;font-size:0.28rem;line-height:0.5rem;">{{ getWord('transfer_rules5') }}</P>
      <p style="font-size:0.28rem;margin-top:0.1rem;"><span style="border-radius:15px;background:#4796fc;color:#FFF;padding:0 0.1rem;margin-right:0.1rem;">1</span>{{ getWord('goto_payment_page') }}</p>
    </div>
    <div class="YHK">
      <div
        class="YHKoverflow-x"
        id="YHKWid"
        ref="YHKWid"
      >
        <label
          class="YHKlab"
          v-for="(it,i) in payList"
          :key="i"
          :id="'label'+i"
        >
          <img class="YHKback">
          <img
            class="YHKpic"
            :src="it.pic"
          >
          <p class="YHKsel">1、{{ getWord(['copy2', 'social_app3', 'username4']) }}<br />{{ getWord(['social_app3', 'username4']) }}：</p>
          <p class="YHKaccNum">{{it.account_num}}</p>
          <p class="YHK2">2、{{ getWord(['select2', 'social_app3', 'username4']) }}</p><input
            @blur="_blured($event)"
            class="YHKinp"
            type="radio"
            name="YHK"
            @click="YHKselect(it)"
          />
        </label>
      </div>
    </div>
    <div class="text_">
      <p style="font-size:0.28rem;margin-top:0.1rem;"><span style="border-radius:15px;background:#4796fc;color:#FFF;padding:0 0.1rem;margin-right:0.1rem;">2</span>{{ getWord('fill_offline_transfer_info') }}</p>
    </div>
    <div class="huikuan">
      <span class="wenzi">{{ getWord('username3') }}</span>
      <input
        @blur="_blured($event)"
        style="font-size:0.28rem;"
        type="text"
        v-model="pname"
        :placeholder="getWord('name_placeholder')"
      >
    </div>
    <div
      class="huikuan"
      style="border-top:none;"
    >
      <span class="wenzi">{{ getWord(['transfer2', 'amount_of_betting']) }}</span>
      <input
        @blur="_blured($event)"
        style="font-size:0.28rem;"
        oninput="value=value.replace(/[^\d]/g,'')"
        type="text"
        v-model="pay_price"
        :placeholder="getWord(['fill', 'transfer2', 'amount_of_betting'])"
      >
    </div>
    <div class="submit_btn">
      <mt-button
        size="large"
        @click="commit()"
        style="color:#FFF;"
      >{{ getWord(['submit', 'deposit', 'apply_for']) }}</mt-button>
    </div>
    <div class="text_ts">
      <P>{{ getWord('tips') }}:</P>
      <div v-html="remark"></div>
    </div>
  </DIV>
</template>
<style lang="scss" scoped>
#recharge {
  padding-bottom: 0.5rem;
  overflow: auto;
}
.mint-button--default {
  background-color: #4796fc;
}
.huikuan {
  border: 1px solid #ccc;
  width: 6.09rem;
  margin-left: 0.68rem;
  line-height: 35px;
  & > span {
    width: 26%;
  }
  & > input {
    width: 74%;
    margin-left: 0;
  }
}
.wenzi {
  float: left;
  font-size: 0.3rem;
  text-indent: 0.2rem;
  color: #000;
  font-size: 0.28rem;
}
.huadong {
  //   overflow-x: scroll;
  height: 3.4rem;
  width: 7.5rem;
  float: left;
}
.text_ {
  margin-left: 0.68rem;
  margin: 5% 0 5% 0.68rem;
  font-size: 0.3rem;
}

input {
  border: none;
  margin-left: 20%;
  width: 3.09rem;
  height: 0.7rem;
}

.YHK {
  width: auto;
  height: 3.2rem;
  margin: 0 0.3rem 0.3rem 0.3rem;
  overflow-y: hidden;
  overflow-x: auto;
  position: relative;
  .YHKoverflow-x {
    width: auto;
    white-space: nowrap;

    .YHKlab {
      width: 100%;
      overflow: hidden;
      height: 3.2rem;
      position: relative;
      margin-right: 10px;
      .YHKback {
        width: 100%;
        overflow: hidden;
        height: 3.2rem;
        background-color: #1671fb;
        border-radius: 0.25rem;
        position: relative;
      }
      .YHKpic {
        height: 2.54rem;
        background-image: url("/static/assets/images2/money/chinaYinHangLogo.png");
        background-size: 100%;
        position: absolute;
        top: -2.6rem;
        left: 0.3rem;
      }
      .YHKaccNum {
        height: 0.5rem;
        color: #fff;
        font-size: 0.24rem;
        position: absolute;
        top: -1.6rem;
        left: 3rem;
      }
      .YHKsel {
        height: 0.5rem;
        color: #fff;
        font-size: 0.26rem;
        position: absolute;
        top: -2.6rem;
        left: 3rem;
      }
      .YHKinp {
        height: 0.3rem;
        width: auto;
        padding: 0;
        margin: 10px;
        position: absolute;
        right: 0;
      }
      .YHK2 {
        height: 0.5rem;
        color: #fff;
        font-size: 0.26rem;
        position: absolute;
        top: -1rem;
        left: 3rem;
      }
    }
  }
}

.submit_btn {
  margin-top: 5%;
  width: 6.09rem;
  height: 0.88rem;
  margin-left: 0.7rem;
}
.text_ts {
  margin-left: 0.7rem;
  margin-top: 0.15rem;
  line-height: 0.36rem;
  margin-right: 1.03rem;
  color: #aaaaaa;
  font-size: 0.24rem;
}
</style>